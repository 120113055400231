import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import AOS from "aos";
import LazyImage from "./LazyImage.jsx";
import "aos/dist/aos.css";
// import { motion } from "framer-motion";

const Card = ({ image, title, content }) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
          <div className="group flex flex-col w-full h-full">
            <div className="w-full h-[90%] relative">
              <Link to="collections">
                <LazyImage
                  src={image}
                  alt={`Card`}
                  CclassName="w-full h-full object-cover rounded HomeSlider"
                />
                <div className="absolute inset-0  bg-black opacity-0 group-hover:opacity-70 transition-opacity flex items-center justify-center">
                  <div className="text-white flex flex-col items-center gap-5 text-center">
                    <h2 className="text-2xl">{title}</h2>
                    <p className="text-justify px-2 md:text-md text-sm">
                      {content}
                    </p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="w-full">
              <h1 className="text-black md:text-xl text-md text-center my-1">
                {title}
              </h1>
            </div>
          </div>
    </>
  );
};

export default Card;
