import React from "react";

const Termas = () => {
    return (
        <div className="container-fluid flex items-cen justify-center">
            <div className="min-h-[20vh] py-[2rem] container flex items-start flex-col gap-8 px-[2rem] pt-[3rem]">
                <div>
                    <h1 className="md:text-2xl text-md font-bold text-justify">Terms & Conditions :</h1>
                </div>
                <div className="w-full flex items-start flex-col gap-[1rem]">
                    <h1 className="md:text-2xl text-md font-bold text-justify">
                        In using this website you are deemed to have read and agreed to the following terms and
                        conditions:
                    </h1>

                    <p className="md:text-xl text-md text-justify">
                        The following terminology applies to these Terms and Conditions, Privacy Statement and
                        Disclaimer Notice and any or all Agreements: ”Client”, “You” and “Your” refers to you, the
                        person accessing this website and accepting the Company’s terms and conditions. “The Company”,
                        “Ourselves”, “We” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both
                        the Client and ourselves, or either the Client or ourselves. All terms refer to the offer,
                        acceptance and consideration of payment necessary to undertake the process of our assistance to
                        the Client in the most appropriate manner, whether by formal meetings of a fixed duration, or
                        any other means, for the express purpose of meeting the Client’s needs in respect of provision
                        of the Company’s stated services/products, in accordance with and subject to, prevailing India
                        Law. Any use of the above terminology or other words in the singular, plural, capitalization
                        and/or he/she or they, are taken as interchangeable and therefore as referring to same.
                    </p>
                </div>



                <div className="w-full flex items-start flex-col gap-[1rem]">
                    <h1 className="md:text-2xl text-md font-bold text-justify">
                    Privacy Statement :
                    </h1>

                    <p className="md:text-xl text-md text-justify">
                    We are committed to protecting your privacy. Authorized employees within the company on a need to know basis only use any information collected from individual customers. We constantly review our systems and data to ensure the best possible service to our customers. Parliament has created specific offenses for unauthorized actions against computer systems and data. We will investigate any such actions with a view to prosecuting and/or taking civil proceedings to recover damages against those responsible Confidentiality
                    </p>


                    <p className="md:text-xl text-md text-justify">
                    We are registered under the Data Protection Act 1998 and as such, any information concerning the Client and their respective Client Records may be passed to third parties. However, Client records are regarded as confidential and therefore will not be divulged to any third party, other than third party suppliers, or if legally required to do so to the appropriate authorities. Clients have the right to request sight of, and copies of any and all Client Records we keep, on the proviso that we are given reasonable notice of such a request. Clients are requested to retain copies of any literature issued in relation to the provision of our services. Where appropriate, we shall issue Client’s with appropriate written information, handouts or copies of records as part of an agreed contract, for the benefit of both parties.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Termas;
