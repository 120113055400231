import React, { useEffect, useState } from "react";

import { Link, NavLink } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import { motion } from "framer-motion";
import logo from "../../src/images/logo.png";

const Navbar = () => {
  // const [scrollDirection, setScrollDirection] = useState("up");

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const currentScrollY = window.scrollY;

  //     if (currentScrollY > 0) {
  //       setScrollDirection("down");
  //     } else {
  //       setScrollDirection("up");
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  // console.log(visible, "scrollDirection  ");

  const [isOpen, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!isOpen);
  };

  const menuVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
    closed: {
      opacity: 0,
      x: "-100%",
      transition: {
        opacity: { duration: 0.3 },
        x: { duration: 0.3 },
      },
    },
  };

  const menuAnimation = isOpen ? "open" : "closed";

  return (
    <>
      {/* <div
        className={`fixed top-0 w-full transition-transform duration-300 transform ${
          visible ? "translate-y-0" : "-translate-y-16"
        }`}
      >
        <div
          className={`w-full bg-black z-50 h-full p-2 flex items-center justify-between px-10 shadow-md`}
        >
          <div className="w-[5rem] md:w-[5rem] ">
            <img
              src={logo}
              alt="logo image"
              className="w-full h-fulll object-cover"
            />
          </div>

          <ul className="lg:flex gap-10 hidden">
            <li className="text-xl cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]">
              <NavLink to="/">HOME</NavLink>{" "}
            </li>
            <li
              className="text-xl cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
            >
              <NavLink to={"/collections"}>COLLECTIONS</NavLink>{" "}
            </li>
            <li
              className="text-xl cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
            >
              {" "}
              <Link to={"/downloads"}>DOWNLOADS</Link>{" "}
            </li>
            <li
              className="text-xl cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
            >
              <Link to={"/network"}>NETWORK</Link>
            </li>

            <li
              className="text-xl cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
            >
              <Link to={"/about"}>ABOUTUS</Link>
            </li>
          </ul>
          <Link to={"/contact-us"}>
            <motion.button
              whileHover={{ scale: 1.1 }}
              className="lg:block hidden px-4 py-2 border-b-4 border border-[#d2ab66] text-white hover:text-white hover:bg-[#d2ab66] transition-all duration-200
"
            >
              Contact Us
            </motion.button>
          </Link>

          <div className="block lg:hidden z-[60]" onClick={handleToggle}>
            <Hamburger
              toggled={isOpen}
              toggle={setOpen}
              size={20} // Specify the size of the hamburger icon
            />
          </div>
        </div>
      </div> */}
      <div
        className={`fixed top-0  w-full bg-black z-50   p-2 flex items-center justify-between px-10 shadow-md
        transition-transform duration-300 transform
        ${visible ? " translate-y-0" : "-translate-y-28"}
        `}
      >
                              <Link to={`/`}>
        <div className="w-[8rem] md:w-[8rem]">
          <img
            src={logo}
            alt="logoImage"
            className="w-full h-full object-cover"
          />
          </div>
          </Link>

        <ul className="lg:flex gap-10   hidden">
          <li className="text-sm cursor-pointer border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]">
            <NavLink to="/">HOME</NavLink>{" "}
          </li>
          <li
            className="text-sm cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
          >
            <NavLink to={"/collections"}>COLLECTIONS</NavLink>{" "}
          </li>
          <li
            className="text-sm cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
          >
            {" "}
            <Link to={"/downloads/category"}>DOWNLOADS</Link>{" "}
          </li>
          <li
            className="text-sm cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
          >
            <Link to={"/network"}>NETWORK</Link>
          </li>

          <li
            className="text-sm cursor-pointer
        border-b-2 border-transparent hover:border-b-2 hover:border-[#d2ab66]
        "
          >
            <Link to={"/about"}>ABOUT US</Link>
          </li>
        </ul>
        <Link to={"/contact-us"}>
          <motion.button
            whileHover={{ scale: 1.1 }}
            className="lg:block hidden px-4 py-2 border-b-4 border border-[#d2ab66] text-white hover:text-white hover:bg-[#d2ab66] transition-all duration-200
"
          >
            Contact Us
          </motion.button>
        </Link>
        <div className="block lg:hidden z-[60]" onClick={handleToggle}>
          <Hamburger
            toggled={isOpen}
            toggle={setOpen}
            size={20} // Specify the size of the hamburger icon
          />
        </div>
      </div>

      <motion.div
        className="fixed md:w-[40%] w-[80%] h-screen  bg-[#111] text-white shadow-xl flex items-start justify-start z-40 top-0 left-0 p-10"
        initial="closed"
        animate={menuAnimation}
        variants={menuVariants}
        exit="closed"
      >
        <ul className="flex gap-10 items-start justify-start flex-col">
          <div className="w-14 h-14">
            <img src={logo} className="w-full h-full object-cover" alt="logo" />
          </div>
          <li className="text-xl cursor-pointer  ">
            <NavLink onClick={handleToggle} to={"/"}>
              HOME
            </NavLink>
          </li>
          <li className="text-xl cursor-pointer   ">
            <NavLink onClick={handleToggle} to={"/collections"}>
              COLLECTIONS
            </NavLink>
          </li>
          <li className="text-xl cursor-pointer   ">
            <NavLink onClick={handleToggle} to={"/downloads/category"}>
              DOWNLOADS
            </NavLink>
          </li>
          <li className="text-xl cursor-pointer  ">
            <NavLink onClick={handleToggle} to={"/network"}>
              NETWORK
            </NavLink>
          </li>
          <li className="text-xl cursor-pointer  ">
            <NavLink onClick={handleToggle} to={"/about"}>
              ABOUT
            </NavLink>
          </li>

          <li>
            <button className="text-xl  ">
              <NavLink to={"/contact-us"} onClick={handleToggle}>
                Contact Us
              </NavLink>
            </button>
          </li>
        </ul>
      </motion.div>
    </>
  );
};

export default Navbar;
