import {
    React, useEffect, useState,
    // useCallback
} from "react";
import { Link } from "react-router-dom";
// import Fade from 'react-reveal/Fade';
// import image1 from "../images/collections1.jpg";
import Fade from "react-reveal/Fade";
import imageNotFound from "../images/imageNotFound.png";
import LazyImage from "./LazyImage.jsx";
import axios from "axios";

const PageCollections = () => {
      const [data, setData] = useState(null);
      const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  async function  fetchData () {
    try {
  const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(`${baseUrl}/api/get-collection`); // Replace with the actual API endpoint
      const jsonData = await response.data.data;
      setData(jsonData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    fetchData();
  },[]);
    return (
      <>
        {isLoading ? (
          <div className="loader">
            <div className="loaderSpin"></div>
          </div>
        ) : data ? (
          data.length >= 0 ? (
            <>
              <div className="container-fluid flex flex-col items-center bg-fixed justify-center pt-[5rem] p-[2rem] gap-[2rem]">
                <div>
                  <h1 className="md:text-3xl font-bold text-xl text-white">
                    Our Collections
                  </h1>
                </div>
                <div className="min-h-[60vh] container flex md:flex-row flex-col-reverse items-start justify-center ">
                  <div className="container flex md:flex-row flex-col-reverse items-start gap-5 justify-center flex-wrap">
                    {data.map((item, index) => (
                      <Fade duration={1000 + 100 * index} left key={index}>
                        <div className="md:w-[25%] h-[15rem]  w-full flex flex-col justify-between items-center rounded">
                          <div className="w-full h-[90%] overflow-hidden">
                            <Link to={`/collections/size/${item.id}`}>
                              {item.bannerimage ? (
                                <LazyImage
                                  key={index}
                                  CclassName="hover:scale-110 transition-all duration-500 cursor-pointer w-full h-full object-cover"
                                  src={encodeURI(baseUrl + "/" + item.bannerimage)}
                                  alt={`Image ${index + 1}`}
                                />
                              ) : (
                                <img
                                  src={imageNotFound}
                                  className="cursor-pointer w-full h-full object-cover"
                                  alt="Glossy"
                                />
                              )}
                            </Link>
                          </div>

                          <div className="bg-white   w-full h-[15%]">
                            <h1
                              style={{ fontSize: "22px" }}
                              className="text-black text-center font-"
                            >
                              {item.title}
                            </h1>
                          </div>
                        </div>
                        {/* <div className="md:w-[32%] w-full md:h-[33rem] h-[20rem] bg-white flex flex-col  border-[#d2ab66] border-[1px] rounded">
                      <div className="w-full h-[80%] overflow-hidden">
                        <img
                          // src={item.bannerimage.replace(/\\\//g, '/')}
                          src={img1}
                          className="hover:scale-125 transition-all duration-500 cursor-pointer w-full h-full object-cover "
                          alt="name1"
                        />
                      </div>

                      <div className="w-full px-5  p-2 min-h-[20%]  flex flex-col items-start justify-center">
                        <div className="w-full px-5  p-2 min-h-[20%]  flex flex-col items-start justify-center">
                          <div className="text-black w-full gap-2 pt-1 flex flex-col items-start">
                            <div className="flex justify-between w-full items-center">
                              <h1 style={{ fontSize: "22px" }}>{item.name}</h1>
                              <Link to={`/downloads/size/${item.id}`}>
                                <div className="cursor-pointer group w-12 m-1 h-12 flex items-center justify-center transition-all hover:bg-black  ml-auto rounded-full  text-end bg-[#d2ab66]">
                                  <AiOutlineEye className="text-black group-hover:text-[#d2ab66] text-2xl" />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                      </Fade>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="container-fluid flex flex-col items-center justify-center pt-[5rem] p-[2rem] gap-[2rem]">
              <h1 className="md:text-3xl font-semibold text-xl text-white">
                Collection
              </h1>
              <p className="md:text-xl md:text-md text-sm lg:w-[50%] w-[80%]">
                Designed to enhance environments with the refined essence of the
                most noble marble, this collection inspires elegant and
                sophisticated atmospheres.
              </p>
              <div className="h-[80vh] container flex md:flex-row flex-col-reverse items-start gap-3 justify-center flex-wrap">
                {data.map((item, index) => (
                  <Fade center duration={1200}>
                    <Link to={`/downloads/catalogue/`}>
                      <div className="w-[8rem] h-[5rem] flex items-center justify-center   bg-[#d2ab66]">
                        <h1 className="text-white">{item.title}</h1>
                      </div>
                    </Link>
                  </Fade>
                ))}
              </div>
            </div> */}
            </>
          ) : (
            // <div className="container-fluid flex flex-col items-center bg-fixed justify-center pt-[5rem] p-[2rem] gap-[2rem]">
            //   <div>
            //     <h1 className="md:text-3xl font-bold text-xl text-white">
            //       Our Collections
            //     </h1>
            //   </div>
            //   <div className="min-h-[60vh] container flex md:flex-row flex-col-reverse items-start justify-center ">
            //     <div className="md:flex-[0.6] md:w-[60%] gap-2 flex-1 w-full flex md:flex-row flex-wrap p-[1rem]  items-center justify-center  ">
            //       <div className="md:w-[30%] h-[15rem]   w-full flex flex-col justify-between items-center">
            //         <div className="w-full h-[90%]">
            //           <Link to={"/size-page"} className="cursor-pointer">
            //             <img
            //               src={image1}
            //               className="w-full h-full object-cover"
            //               alt="Glossy"
            //             />
            //           </Link>
            //         </div>

            //         <div className="bg-white   w-full h-[10%]">
            //           <h1 className="text-black text-center font-">Glossy</h1>
            //         </div>
            //       </div>
            //       <div className="md:w-[30%] h-[15rem]   w-full flex flex-col justify-between items-center">
            //             <div className="w-full h-[90%]">
            //             <Link to={"/size-page"} className="cursor-pointer">
            //                 <img
            //                 src={image1}
            //                 className="w-full h-full object-cover"
            //                 alt="Glossy"
            //                 />
            //             </Link>
            //             </div>

            //             <div className="bg-white   w-full h-[10%]">
            //             <h1 className="text-black text-center font-">Glossy</h1>
            //             </div>
            //         </div>
            //     </div>

            //     <div className="md:flex-[0.4]  md:w-[40%] flex-1 w-full flex flex-col md:items-center items-start md:justify-center  justify-start gap-[1rem]">
            //       <div className="w-full h-ful md:px-0 px-4 gap-2 flex md:flex-row flex-col flex-wrap md:items-start justify-center">
            //         <div className="md:w-[30%] w-[60%] flex flex-col">
            //           <label htmlFor="" className="m-1">
            //             Category
            //           </label>
            //           <select className="bg-transparent b border-[#d2ab66] p-[.2rem] border-[1px] text-white">
            //             <option className="bg-[#d2ab66]">Glossy</option>
            //             <option className="bg-[#d2ab66]">Sugar</option>
            //             <option className="bg-[#d2ab66]">Craving</option>
            //           </select>
            //         </div>

            //         <div className="md:w-[30%] w-[60%] flex flex-col">
            //           <label htmlFor="" className="m-1">
            //             Sub Category
            //           </label>
            //           <select className="bg-transparent b border-[#d2ab66] p-[.2rem] border-[1px] text-white">
            //             <option className="bg-[#d2ab66]">Glossy1</option>
            //             <option className="bg-[#d2ab66]">Sugar1</option>
            //             <option className="bg-[#d2ab66]">Craving1</option>
            //           </select>
            //         </div>

            //         <div className="md:w-[30%] w-[60%] flex flex-col">
            //           <label htmlFor="" className="m-1">
            //             Size
            //           </label>
            //           <select className="bg-transparent b border-[#d2ab66] p-[.2rem] border-[1px] text-white">
            //             <option className="bg-[#d2ab66]">400*500</option>
            //             <option className="bg-[#d2ab66]">400*600</option>
            //             <option className="bg-[#d2ab66]">500*500</option>
            //           </select>
            //         </div>
            //       </div>

            //       <div className="w-full h-full   gap-2 flex flex-wrap items-center justify-center p-2">
            //         <div className="bg-yellow-200 w-[30%] h-[5rem] rounded-sm">
            //           <img
            //             src={tyles1}
            //             alt="Glossy"
            //             className="w-full h-full object-cover"
            //           />
            //         </div>
            //         <div className="bg-yellow-200 w-[30%] h-[5rem] rounded-sm">
            //           <img
            //             src={tyles2}
            //             alt="Glossy"
            //             className="w-full h-full object-cover"
            //           />
            //         </div>
            //         <div className="bg-yellow-200 w-[30%] h-[5rem] rounded-sm">
            //           <img
            //             src={tyles3}
            //             alt="Glossy"
            //             className="w-full h-full object-cover"
            //           />
            //         </div>
            //         <div className="bg-yellow-200 w-[30%] h-[5rem] rounded-sm">
            //           <img
            //             src={tyles4}
            //             alt="Glossy"
            //             className="w-full h-full object-cover"
            //           />
            //         </div>
            //         <div className="bg-yellow-200 w-[30%] h-[5rem] rounded-sm">
            //           <img
            //             src={tyles5}
            //             alt="Glossy"
            //             className="w-full h-full object-cover"
            //           />
            //         </div>
            //         <div className="bg-yellow-200 w-[30%] h-[5rem] rounded-sm">
            //           <img
            //             src={tyles6}
            //             alt="Glossy"
            //             className="w-full h-full object-cover"
            //           />
            //         </div>
            //       </div>
            //     </div>
            //   </div>
            // </div>
            <div className="noData">
              <p>No data available.</p>
            </div>
          )
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )}
      </>
    );
};

export default PageCollections;
