import { React, useEffect, useState } from "react";
// import TestiMonialsDetails from "./TestiMonialsDetails";
import OwlCarousel from "react-owl-carousel";
import Fade from "react-reveal/Fade";
import LazyImage from "./LazyImage.jsx";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
// import inovationimg1 from "../images/inovationimg1.jpg";
// import inovationimg2 from "../images/inovationimg2.jpg";
// import inovationimg3 from "../images/inovationimg3.jpg";
import axios from "axios";
// import './TestiMonials.css'
const TestiMonials = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${baseUrl}/api/projectBanner`); // Replace with the actual API endpoint
        const jsonData = await response.data.data;
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const options = {
    loop: false,
    center: true,
    items: 2,
    margin: 1,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: true,
    navText: [
      "<svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='#d2ab66' class='bi bi-caret-left-fill' viewBox='0 0 16 16'><path d='m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z'/></svg>",
      "<svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='#d2ab66' class='bi bi-caret-right-fill' viewBox='0 0 16 16'><path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/></svg>",
    ], // Custom navigation icons
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1.7,
      },
      1000: {
        items: 1.7,
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        data.length > 0 ? (
          <section id="testimonial" className="testimonials pt-70 pb-70">
            <div className="mt-5">
              <Fade duration={700} left>
                <h2
                  className="miniTitle text-center"
                  style={{ fontSize: "30px" }}
                >
                  Our Projects
                </h2>
              </Fade>
              <Fade duration={1200} left>
                <div className="text-center ">
                  {/* <h3 className="sectionTitle">What Our Clients are Saying?</h3> */}
                </div>
              </Fade>
              <Fade duration={1700} left>
                <p className="text-center ">
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna. */}
                </p>
              </Fade>
              <div className="row">
                <div className="col-md-12">
                  <OwlCarousel
                    id="customer-testimonoals"
                    className="owl-carousel owl-theme"
                    {...options}
                  >
                    {
                      data.map((item, index) => {
                        return (
                          <div key={item.id} className="item">
                            <LazyImage
                              key={index}
                              CclassName="HomeSlider"
                              src={encodeURI(baseUrl + "/" + item.image)}
                              alt={`Image ${index + 1}`}
                            />
                          </div>
                          // <TestiMonialsDetails item={item} key={item._key} />
                        );
                      })
                    }
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )
      ) : (
        <div className="noData">
          <p>No data available.</p>
        </div>
      )}
    </>
  );
};

export default TestiMonials;
