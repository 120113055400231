import React, { useEffect, useState } from "react";
import HeroArea from "./HeroArea";
import PrimumFloring from "./PrimumFloring";
import YourSpace from "./YourSpace";
import Inovation from "./Inovation";
import ProffsionalArea from "./ProffsionalArea";
import SubAbout from "./SubAbout"; 
import {FaArrowUp} from 'react-icons/fa'
import {motion} from 'framer-motion'

const Home = () => {
  const [isAtTop, setIsAtTop] = useState(true);

  useEffect(() => {
    // Function to handle scroll event
    const handleScroll = () => {
      // Check the scroll position to determine if you're at the top
      const isTop = window.scrollY === 0;
      setIsAtTop(isTop);
    };

    // Attach the scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  return (
    <>
      {!isAtTop && (
        <motion.button
        initial={{y:-10, opacity:0}}
          whileInView={{y:0, opacity:1}}
          transition={{duration:1.5,ease: "easeInOut"}}
          onClick={() => scrollToTop()}
          className="fixed bottom-[5%] z-[99] transition-all right-[5%] p-[1rem] bg-[#d2ab66] text-black w-12 h-12 rounded-full"
        >
          <FaArrowUp />
        </motion.button>
      )}
      <div>
        <HeroArea />
      </div>
      <div className="md:my-[4rem] my-[2.5rem]">
        <PrimumFloring />
      </div>
      <div className="md:my-[4rem] my-[2.5rem]">
        <YourSpace />
      </div>
      <div className="md:my-[4rem] my-[2.5rem]">
        <Inovation />
      </div>
      <div className="md:my-[4rem] my-[2.5rem]">
        <ProffsionalArea />
      </div>
      <div className="md:my-[4rem] my-[2.5rem]">
        <SubAbout />
      </div>
    </>
  );
};

export default Home;
