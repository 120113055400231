import { React, useEffect, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
import "./About.css";
import LazyImage from "../LazyImage.jsx";
import axios from "axios";
import { motion } from "framer-motion";

const AboutUs = () => {
  function isOddOrEven(number) {
    if (typeof number !== "number") {
      return "Input is not a number";
    }

    if (number % 2 === 0) {
      return "Even";
    } else {
      return "Odd";
    }
  }
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${baseUrl}/api/aboutusBanner`); // Replace with the actual API endpoint
        const jsonData = await response.data.data;
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        data.length > 0 ? (
          <div className="container-fluid flex items-center justify-center ">
            <div className="container flex items-center justify-center md:p-[2rem] p-[1rem] md:px-[3rem] px-0 md:mt-[2rem] mt-[3rem]">
              <div className="w-full h-full  flex flex-col items-center justify-center gap-[1.5rem]">
                <div className="w-full">
                  <h1 className="md:text-3xl text-2xl text-center font-bold">
                    About Us
                  </h1>
                </div>

                <div className="w-full flex flex-col items-center justify-center">
                  {/* section 1 */}
                  {data.map((item, index) => (
                    <div
                      key={index}
                      className={
                        isOddOrEven(index) === "Odd"
                          ? "w-full flex items-center justify-center lg:flex-row-reverse flex-col"
                          : "w-full flex items-center justify-center lg:flex-row flex-col"
                      }
                    >
                      <motion.div
                        initial={{ opacity: 0, x: -100 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 1 }}
                        className={
                          isOddOrEven(index) === "Odd"
                            ? "lg:w-[50%] md:w-[80%] w-full flex flex-col p-8 items-start justify-center gap-2 lg:gap-8 border-[#d2ab66] border-dashed lg:border-l-[1px]"
                            : "lg:w-[50%] md:w-[80%] w-full flex flex-col p-8 items-start justify-center gap-2 lg:gap-8 border-[#d2ab66] border-dashed lg:border-r-[1px]"
                        }
                      >
                        <h1 className="md:text-2xl text-md font-bold">
                          {item.title}
                        </h1>
                        <p className="md:text-xl text-md  text-justify">
                          {item.description}
                        </p>
                      </motion.div>

                      <motion.div
                        initial={{ opacity: 0, y: -100 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        className="lg:w-[50%] md:w-[80%] h-[300px] lg:border-none border-b-[1px] border-dashed  border-[#d2ab66]   w-full flex flex-col p-8 items-start justify-center   "
                      >
                        <LazyImage
                          key={index}
                          CclassName="w-full h-full object-cover rounded-image"
                          src={encodeURI(baseUrl + "/" + item.image)}
                          alt={`Image ${index + 1}`}
                        />
                      </motion.div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )
      ) : (
        <div className="noData">
          <p>No data available.</p>
        </div>
      )}
    </>
  );
};

export default AboutUs;
