import React from "react";
const Item = ({ Links, title }) => {
  return (
    <ul className="lg:flex sm:flex md:flex justify-center">
      <div>
        <h1 className="mb-1 font-semibold" style={{ fontSize: "30px" }}>
          {title}
        </h1>

        {Links.map((link) => (
          <li key={link.name}>
            <a
              className="text-white hover:text-[#d2ab66]  duration-300
        text-sm cursor-pointer leading-6"
              href={link.link}
              style={{ fontSize: "20px" }}
            >
              {link.name}
            </a>
          </li>
        ))}
      </div>
    </ul>
  );
};

export default Item;
