import React from "react";
import Network from "../images/Indiamap.svg";
import Loaction from "../images/Location.svg";

const PageNetwork = () => {
  return (
    <div className="container-fluid flex items-center justify-center">
      <div className="container flex flex-col items-center md:p-[2rem] gap-[2rem] p-[1rem] justify-center md:mt-[2rem] mt-[3rem]">
        <div className="w-full h-full flex flex-col items-center justify-center md:px-[3rem] px-5 gap-[2rem]">
          <h1 className="md:text-3xl font-bold text-md">
            Network
          </h1>
          <p className="md:text-xl font-semibold text-md text-justify">
            At Avio Ceramic, we take pride in our extensive network and
            commitment to delivering quality products to every corner of the
            country. With a robust supply chain and a dedication to excellence,
            we have successfully expanded our reach to cater to many
            retailers and dealers across India.
          </p>
          <p className="md:text-xl font-semibold text-md text-justify">
            The exponential export network of the company is complimented by the
            extensive domestic presence, which covers all over India through one
            of the widest networks of dealers and distributors across the
            nation.
          </p>
        </div>

        <div className="w-full h-full flex flex-col items-center justify-center md:px-[3rem] px-5 gap-[2rem]">
          <h1 className="md:text-3xl font-bold text-md">
            SUPPLYING TO MORE THAN 250 RETAILERS / DEALERS ACROSS INDIA{" "}
          </h1>{" "}
          <h1
            className="md:text-3xl  font-bold flex text-md"
            style={{ whiteSpace: "nowrap" }}
          >
            STATES COVERED
            <div className="w-[13%] flex justify-center h-full">
              <img
                src={Loaction}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>
          </h1>
          <div className="w-[50%] flex justify-center h-full">
            <img src={Network} alt="" className="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNetwork;
