import React,{useState,useEffect} from "react";
import Card from "./Card";
import OwlCarousel from "react-owl-carousel";
// import LazyImage from "./LazyImage.jsx";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import axios from "axios";
// import image1 from "../images/oursection1.jpg";
// import image2 from "../images/ourspace2.jpg";
// import image3 from "../images/ourspace3.png";
// import { motion } from "framer-motion";

const YourSpace = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${baseUrl}/api/tilesBanner`); // Replace with the actual API endpoint
        const jsonData = await response.data.data;
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  const options = {
    loop: false,
    center: false,
    items: 3,
    margin: 1,
    autoplay: true,
    dots: true,
    autoplayTimeout: 8500,
    smartSpeed: 450,
    nav: false,
    navText: [
      // "<svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='#d2ab66' class='bi bi-caret-left-fill' viewBox='0 0 16 16'><path d='m3.86 8.753 5.482 4.796c.646.566 1.658.106 1.658-.753V3.204a1 1 0 0 0-1.659-.753l-5.48 4.796a1 1 0 0 0 0 1.506z'/></svg>",
      // "<svg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='#d2ab66' class='bi bi-caret-right-fill' viewBox='0 0 16 16'><path d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/></svg>",
      "<svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='#d2ab66' class='bi bi-chevron-left' viewBox='0 0 16 16'><path fillRule='evenodd' d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0'/></svg>",
      "<svg xmlns='http://www.w3.org/2000/svg' width='50' height='50' fill='#d2ab66' class='bi bi-chevron-right' viewBox='0 0 16 16'><path fillRule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708'/></svg>"
    ],
    responsive: {
      0: {
        items: 1,
      },
      790:{
        items: 1.7,
      },
      1000: {
        items: 2,
      },
      1300: {
        items: 3,
      },
    },
  };

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        data.length > 0 ? (
          <div className="container-fluid bg-[#f8ecec] flex items-center justify-center ">
            <div className="container  font-bold px-5 text-black flex gap-6 py-3 items-center pb-8 justify-center flex-col">
              <div className="w-full h-full pt-[2rem]">
                <h1
                  data-aos="zoom-out-right"
                  data-aos-duration="1600"
                  data-aos-easing="linear"
                  className="text-black md:text-3xl text-md text-center"
                >
                  Tiles that Adorn Your Spaces
                </h1>
              </div>

              <div className="w-full h-full  gap-[2rem]  flex flex-col md:flex-row items-center justify-center">
                   <OwlCarousel
                   id="your-space-slider"
                   className="owl-carousel owl-theme"
                   {...options}
                 >
                   {
                     data.map((item, index) => {
                       return (
                         <div key={item.id} className="item">
                           <Card
                            image={encodeURI(baseUrl + "/" + item.image)}
                            title={item?.title}
                            content={item?.description}
                          />
                         </div>
                       );
                     })
                   }
                 </OwlCarousel>
              </div>

              {/* responsive card code */}

              {/* <div className="lg:hidden   h-full flex items-center justify-center">
          <Swiper>
            <SwiperSlide>
              <Card />
            </SwiperSlide>
            <SwiperSlide>
              <Card />
            </SwiperSlide>
            <SwiperSlide>
              <Card />
            </SwiperSlide>
          </Swiper>
        </div> */}
            </div>
          </div>
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )
      ) : (
        <div className="noData">
          <p>No data available.</p>
        </div>
      )}
    </>
  );
};

export default YourSpace;
