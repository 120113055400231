import React from "react";
// import SocialIcons from "./SocialIcons";
import ItemContainer from "./ItemContainer"; 
// import { Icons } from "./Menus";
const Footer = () => {
  return (
    <div className="">
      <footer className="  bg-[#111] text-white border-t-[1px] border-[#d2ab66]">
        <ItemContainer />
        <div
          className="flex md:flex-row flex-col items-center justify-between px-10 gap-10
      text-center pt-2 text-white text-sm pb-8 border-t-[1px] border-[#d2ab66]"
        >
          <span>© 2023 Avio Ceramic</span>
          {/* <SocialIcons Icons={Icons} /> */}
        </div>
      </footer>
    </div>
  );
};
export default Footer;
