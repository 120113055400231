import { React, useEffect, useState, useCallback } from "react";
// import img1 from "../images/collections1.jpg";
// import img2 from "../images/collections2.jpg";
// import img3 from "../images/collections3.png";
import Zoom from "react-reveal/Zoom";
import { Link, useParams } from "react-router-dom";
import imageNotFound from "../images/imageNotFound.png";
import LazyImage from "./LazyImage.jsx";

// import { HiArrowNarrowRight } from "react-icons/hi";
// import { motion } from "framer-motion";
// import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";

const PageCatalogueDownolad = () => {
    const params = useParams();
  const fetchData = useCallback(async () => {
    try {
  const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axios.post(
        `${baseUrl}/api/getCatalogue`,
        // "http://192.168.0.169:9000/api/getCatalogue",
        { subcategory_id: params.categoryId, size_id: Number(params.sizeId) }
      ); // Replace with the actual API endpoint
      const jsonData = await response.data;
      setData(jsonData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [params.categoryId,params.sizeId]);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    fetchData();
  }, [fetchData]);
    return (
      <>
        {isLoading ? (
          <div className="loader">
            <div className="loaderSpin"></div>
          </div>
        ) : data ? (
          data.length > 0 ? (
            <div className="container-fluid flex flex-col items-center justify-center pt-[5rem] p-[2rem] gap-[2rem]">
              <h1 className="md:text-3xl font-semibold text-xl text-white">
                Catalogues
              </h1>
              <p className="md:text-xl md:text-md text-sm lg:w-[50%] w-[80%]">
                Designed to enhance environments with the refined essence of the
                most noble marble, this collection inspires elegant and
                sophisticated atmospheres.
              </p>
              <div className="h-[100%] container flex lg:flex-row items-start gap-3 justify-center flex-wrap">
                {data.map((item, index) => (
                  <Zoom center duration={1200}>
                    <div className="md:w-[25%] h-[fit-content]  w-full flex flex-col justify-between items-center rounded">
                      <div className="w-full h-[90%] overflow-hidden">
                        <Link
                          to={`/downloads/readCatalogue/${item.id}`}
                          className="cursor-pointer"
                        >
                          {item.thumbnail ? (
                            <LazyImage
                              key={index}
                              CclassName="hover:scale-110 transition-all duration-500 cursor-pointer w-full h-[12rem] object-cover"
                              src={encodeURI(baseUrl + "/" + item.thumbnail)}
                              alt={`Image ${index + 1}`}
                            />
                          ) : (
                            <img
                              src={imageNotFound}
                              className="cursor-pointer w-full h-[12rem] object-fit"
                              alt="Glossy"
                            />
                          )}
                        </Link>
                      </div>

                      <div className="bg-white   w-full h-[fit-content]">
                        <h1
                          style={{ fontSize: "22px" }}
                          className="text-black text-center font-"
                        >
                          {item.title}
                        </h1>
                      </div>
                    </div>
                  </Zoom>
                ))}
              </div>
            </div>
          ) : (
            <div className="noData">
              <p>No data available.</p>
            </div>
          )
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )}
      </>
    );
};

export default PageCatalogueDownolad;
