import { React, useEffect, useState, useCallback } from "react";
// import img1 from "../images/collections1.jpg";
// import img2 from "../images/collections2.jpg";
// import img3 from "../images/collections3.png";
import { Link, useParams } from "react-router-dom";

// import {HiArrowNarrowRight} from "react-icons/hi";
// import {motion} from "framer-motion";
// import { AiOutlineEye } from "react-icons/ai";
// import Fade from "react-reveal/Fade";
import Zoom from "react-reveal/Zoom";
import axios from 'axios'
import imageNotFound from "../images/imageNotFound.png";
import LazyImage from "./LazyImage.jsx";

const PageSizeDownload = () => {
     const params = useParams();
  const fetchData = useCallback(async () => {
    try {
  const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${baseUrl}/api/getSizebySubcategoryid/${params.id}`
      ); // Replace with the actual API endpoint
      const jsonData = await response.data;
      setData(jsonData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  },[params.id]);
  

      const [data, setData] = useState(null);
      const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
      useEffect(() => {
        fetchData();
      }, [fetchData]);
    return (
      <>
        {isLoading ? (
          <div className="loader">
            <div className="loaderSpin"></div>
          </div>
        ) : data ? (
          data.length > 0 ? (
            <div className="container-fluid flex flex-col items-center justify-center pt-[5rem] p-[2rem] gap-[2rem]">
              <h1 className="md:text-3xl font-semibold text-xl text-white">
                Size
              </h1>
              <p className="md:text-xl md:text-md text-sm lg:w-[50%] w-[80%]">
                Designed to enhance environments with the refined essence of the
                most noble marble, this collection inspires elegant and
                sophisticated atmospheres.
              </p>
              <div className="h-[100%] container flex lg:flex-row items-start gap-3 justify-center flex-wrap">
                {data.map((item, index) => (
                  <Zoom center duration={1200}>
                    <div className="lg:w-[15%] md:w-[30%] sm:w-[40%] h-[12rem] w-[70%] flex flex-col justify-between items-center rounded">
                      <div className="w-full h-[90%] overflow-hidden">
                        <Link
                          to={`/downloads/catalogue/${item.id}/${params.id}`}
                        >
                          {item.bannerimage ? (
                            <LazyImage
                              key={index}
                              CclassName="hover:scale-110 transition-all duration-500 cursor-pointer w-full h-full object-cover"
                              src={encodeURI(baseUrl + "/" + item.bannerimage)}
                              alt={`Image ${index + 1}`}
                            />
                          ) : (
                            <img
                              src={imageNotFound}
                              className="cursor-pointer w-full h-full object-cover"
                              alt="Glossy"
                            />
                          )}
                        </Link>
                      </div>

                      <div className="bg-white   w-full h-[20%]">
                        <h1
                          style={{ fontSize: "22px" }}
                          className="text-black text-center font-"
                        >
                          {item.name}
                        </h1>
                      </div>
                    </div>
                  </Zoom>
                ))}
              </div>
            </div>
          ) : (
            <div className="noData">
              <p>No data available.</p>
            </div>
          )
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )}
      </>
    );
};

export default PageSizeDownload;
