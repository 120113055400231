// actions.js
export const setData = (data) => ({
    type: 'SET_DATA',
    payload: data,
});

export const setIsLoading = (isLoading) => ({
    type: 'SET_IS_LOADING',
    payload: isLoading,
});

export const setActiveSlide = (activeSlide) => ({
    type: 'SET_ACTIVE_SLIDE',
    payload: activeSlide,
});

export const setSliderReady = (sliderReady) => ({
    type: 'SET_SLIDER_READY',
    payload: sliderReady,
});
export const setPrevSlideReady = (prevSliderReady) => ({
    type: 'SET_PREV_SLIDE_READY',
    payload: prevSliderReady,
});
