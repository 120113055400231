import {
  React,
  useEffect, useState, useCallback
} from "react";
import axios from "axios";
import { useParams, useNavigate, Link } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import image from "../../src/images/logo.png";
import background from "../../src/images/readcatlogbg.png";
import { calcLength } from "framer-motion";

const FlipComponent = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const [data, setData] = useState([]);
 const [pdf, setpdf] = useState([]);
  const [alignment, setAlignment] = useState('horizontal');
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  function isImageByExtension(filename) {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"]; // Add more if needed
    const extension = filename.split(".").pop().toLowerCase();
    return imageExtensions.includes(extension);
  }

  // const fetchData = useCallback(async () => {
  //   try {
  //     const response = await axios.get(
  //       `${baseUrl}/api/getCatalogueImages/${params.id}`
  //     ); // Replace with the actual API endpoint
  //     const jsonData = await response.data.images;
  //     setAlignment(response.data.alignment);
  //     if (jsonData) {
  //       let array=[]
  //       let pdfarray=[]
  //         const myPromise = new Promise((resolve, reject) => {
  //             array = jsonData.filter((item) => isImageByExtension(item));
  //             pdfarray = jsonData.filter((item) => !isImageByExtension(item));
  //             resolve(array,pdfarray)
  //         });

  //       myPromise.then((data,pdfdata) => {
  //         setData(data);
  //         setpdf(pdfdata);
  //         setTimeout(() => {
  //           setIsLoading(false);
  //         }, 2000);
  //       }).catch((error) => {
  //         setData([])
  //         setpdf([]);
  //         setIsLoading(false);

  //       });
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //     setIsLoading(false);
  //   }
  // }, [params.id, baseUrl]);

  const fetchData = useCallback(async () => {
    try {
      const response = await axios.get(`${baseUrl}/api/getCatalogueImages/${params.id}`);
        
        const jsonData = response.data.images;
        setAlignment(response.data.alignment);
        
        if (jsonData) {
          // console.log(jsonData);
          const filteredData = jsonData.filter(item => isImageByExtension(item));
          const filteredPdf = jsonData.filter(item => !isImageByExtension(item));
    
          setData(filteredData);
          setpdf(filteredPdf);
          setIsLoading(false);  
        }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [params.id, baseUrl]);
  useEffect(() => {
    fetchData();
  }, []);
  
  
  
 ;
  const divStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
  };
  // function convertToHttps(url) {
  //   // Check if the URL already starts with 'https://'
  //   if (url.startsWith("https://")) {
  //     return url; // Already HTTPS, no need to change anything
  //   }

  //   // Replace 'http://' with 'https://'
  //   return url.replace(/^http:\/\//i, "https://");
  // }
  function isMobileOrTablet() {
    const userAgent = navigator.userAgent;
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      userAgent
    );
  }
  // function getImageExtension(url) {
  //   // Extract the part of the URL after the last dot
  //   const lastDotIndex = url.lastIndexOf(".");

  //   // Check if there is a dot in the URL
  //   if (lastDotIndex !== -1) {
  //     // Get the substring starting from the last dot to the end of the URL
  //     const extension = url.substring(lastDotIndex + 1).toLowerCase();
  //     return extension;
  //   } else {
  //     // If there is no dot in the URL, it doesn't have an extension
  //     return null;
  //   }
  // }
  const handleDownloadPDF = () => {
    const pdfUrl = baseUrl + "/" + pdf[0]
    // const corsAnywhereUrl = 'https://cors-anywhere.herokuapp.com/';
    fetch(`${pdfUrl}`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
    })
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
         const a = document.createElement("a");
         a.href = url;
         a.download = "downloaded_file.pdf";
         document.body.appendChild(a);
         a.click();
         document.body.removeChild(a);
         window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading PDF:", error);
        const url = baseUrl + "/" + pdf[0];
        const a = document.createElement("a");
        a.href = url;
        a.download = "downloaded_file.pdf";
        a.target = '_blank'
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      });


    // const pdf = new jsPDF();

    // items.forEach((img, index) => {
    //   if (index > 0) {
    //     pdf.addPage();
    //   }

// const imageExtension = getImageExtension(img);

//       if (imageExtension !== null) {
//         console.log(imageExtension);
//         pdf.addImage(img, 'JPEG', 10, 10, 190, 270); // Adjust the position and size as needed
//       }
//     });

//     pdf.save("images.pdf");
  };
  const navigate = useNavigate();
  const handelBack = () => {
    navigate(-1);
  }
  const [items, setItems] = useState([]);
  function setShowImageFun(image) {
    if(image) {
      const newItems = items;
      newItems.push(image);
      setItems(newItems);

    }
  }

 
 
  
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        data?.length > 0 ? (
          <>
            <div className="container-fluid flex items-start m-[1rem]">
              <div className="w-full h-full flex gap-5">
                <Link to={`/`}>
                  <div className="w-[8rem] h-[4rem]">
                    <img
                      src={image}
                      alt="logo"
                      className="w-full h-full object-cover"
                    />
                  </div>
                </Link>

                <div className="w-full flex justify-between h-full p-3 ">
                  {/* <a href="./" className="p-1" download> */}
                  <button
                    className="bg-[#d2ab66] p-2"
                    onClick={handleDownloadPDF}
                  >
                    Download Catalogue
                  </button>
                  {/* </a> */}
                  <button
                    className="bg-[#d2ab66] p-2 flex"
                    onClick={handelBack}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      className="bi bi-box-arrow-left mr-2 rounded"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fillRule="evenodd"
                        d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0z"
                      />
                      <path
                        fillRule="evenodd"
                        d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
                      />
                    </svg>
                    Back
                  </button>
                </div>
              </div>
            </div>
            <div
              style={divStyle}
              className="container-fluid flex flex-col items-center justify-center pt-[0rem] p-[2rem] gap-[2rem]"
            >
              <h3
                className="flex justify-center"
                style={{
                  fontSize: "30px",
                  width: "500px",
                  color: "#00000096",
                  // marginBottom: "-100px",
                }}
              >
                {" "}
                {"<< Swipe or Click"}
              </h3>
                {alignment === "vertical" ? (
                  <div className="container flex md:flex-row flex-col-reverse items-start justify-center ">
                    {isMobileOrTablet() ? (
                      <>
                        {data && data.map((image, index) => (
                          <div style={{ display: "none" }} key={index}>
                            <img
                              src={encodeURI(baseUrl + "/" + image)}
                              alt={`Page `}
                              onLoad={() => setShowImageFun(image)}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        ))}
                        <HTMLFlipBook
                          width={300}
                          height={300}
                          mobileScrollSupport={true}
                          style={{
                            marginBottom: "100px",
                          }}
                        >
                          {data.map((image, index) => (
                            <div
                              className="page "
                              key={index}
                              style={{ backgroundColor: "white" }}
                            >
                              <img
                                src={encodeURI(baseUrl + "/" + image)}
                                alt={`Page `}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                          ))}
                        </HTMLFlipBook>
                      </>
                    ) : (
                      <>
                        { data && data?.map((image, index) => (
                          <div style={{ display: "none" }} key={index}>
                            <img
                              src={encodeURI(baseUrl + "/" + image)}
                              alt={`Page `}
                              onLoad={() => setShowImageFun(image)}
                              style={{ width: "100%", height: "100%" }}
                            />
                          </div>
                        ))}
                        <HTMLFlipBook
                          width={500}
                          height={500}
                          mobileScrollSupport={true}
                          style={{
                            marginBottom: "100px",
                          }}
                        >
                          {data.map((image, index) => (
                            <div
                              className="page"
                              style={{ backgroundColor: "white" }}
                              key={index}
                            >
                              <img
                                src={encodeURI(baseUrl + "/" + image)}
                                alt={`Page `}
                                style={{ width: "100%", height: "100%" }}
                              />
                            </div>
                          ))}
                        </HTMLFlipBook>
                      </>
                    )}
                  </div>
               ) : (
                <div className="container flex md:flex-row flex-col-reverse items-start justify-center ">
                {isMobileOrTablet() ? (
                  <>
                    { data && data.map((image, index) => (
                      <div style={{ display: "none" }}
                      key={index}>
                        
                        <img
                          src={encodeURI(baseUrl + "/" + image)}
                          alt={`Page `}
                          onLoad={() => setShowImageFun(image)}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    ))}
                    <HTMLFlipBook
                      width={300}
                      height={300}
                      mobileScrollSupport={true}
                      style={{
                        marginBottom: "100px",
                      }}
                    >
                      {data.map((image, index) => (
                        <div
                          className="page "
                          style={{ backgroundColor: "white" }}
                          key={index}
                        >
                          <img
                            src={encodeURI(baseUrl + "/" + image)}
                            alt={`Page `}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      ))}
                    </HTMLFlipBook>
                  </>
                ) : (
                  <>
                    { data && data.map((image, index) => (
                      <div style={{ display: "none" }}
                      key={index}>
                        <img
                          src={encodeURI(baseUrl + "/" + image)}
                          alt={`Page `}
                          onLoad={() => setShowImageFun(image)}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </div>
                    ))}
                    <HTMLFlipBook
                      width={1200}
                      height={600}
                      mobileScrollSupport={true}
                      style={{
                        marginBottom: "100px"
                      }}
                    >
                      {data.map((image, index) => (
                        <div
                          className="page "
                          style={{ backgroundColor: "white" }}
                          key={index}
                        >
                          <img
                            src={encodeURI(baseUrl + "/" + image)}
                            alt={`Page `}
                            style={{ width: "100%", height: "100%" }}
                          />
                        </div>
                      ))}
                    </HTMLFlipBook>
                  </>
                )}
              </div>
                  )}
            </div>

         
                  
          </>
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )
      ) : (
        <div className="noData">
          <p>No data available.</p>
        </div>
      )}
    </>
  );
};

export default FlipComponent;
