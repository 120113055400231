import { React, useEffect, useState, useCallback } from "react";
import imageNotFound from "../images/imageNotFound.png";
// import img2 from "../images/collections2.jpg";
// import img3 from "../images/collections3.png";
import Fade from "react-reveal/Fade";
import {Link, useParams} from "react-router-dom";
import LazyImage from "./LazyImage.jsx";
// import {HiArrowNarrowRight} from "react-icons/hi";
import {motion} from "framer-motion";
// import { AiOutlineEye } from "react-icons/ai";
import axios from "axios";

const PageSubCategoryDownload = () => {
    const params = useParams()
  const fetchData = useCallback(async () => {
    try {
  const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${baseUrl}/api/getSubCategory/${params.id}`
      ); // Replace with the actual API endpoint
      const jsonData = await response.data;
      setData(jsonData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [params.id]);

  const [data, setData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
      useEffect(() => {
        fetchData();
      }, [fetchData]);
    return (
      <>
        {isLoading ? (
          <div class="loader">
            <div class="loaderSpin"></div>
          </div>
        ) : data ? (
          data.length > 0 ? (
            <div className="container-fluid flex flex-col items-center justify-center pt-[5rem] p-[2rem] gap-[2rem]">
              <motion.div
                initial={{ opacity: 0, x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                className="w-full flex items-center flex-col gap-3  text-center"
              >
                <h1 className="md:text-3xl font-semibold text-xl text-white">
                  Sub Categories
                </h1>
                <p className="md:text-xl md:text-md text-sm lg:w-[50%] w-[80%]">
                  Designed to enhance environments with the refined essence of
                  the most noble marble, this collection inspires elegant and
                  sophisticated atmospheres.
                </p>
              </motion.div>
              <div className="container flex md:flex-row flex-col-reverse items-start gap-5 justify-center flex-wrap">
                {data.map((item, index) => (
                  <Fade duration={1000 + 100 * index} left key={index}>
                    <div className="md:w-[25%] h-[15rem]  w-full flex flex-col justify-between items-center rounded">
                      <div className="w-full h-[90%] overflow-hidden">
                        <Link
                          to={`/downloads/size/${item.id}`}
                          className="cursor-pointer"
                        >
                          {item.bannerimage ? (
                            <LazyImage
                              key={index}
                              CclassName="hover:scale-110 transition-all duration-500 cursor-pointer w-full h-full object-cover"
                              src={encodeURI(baseUrl + "/" + item.bannerimage)}
                              alt={`Image ${index + 1}`}
                            />
                          ) : (
                            <img
                              src={imageNotFound}
                              className="cursor-pointer w-full h-full object-cover"
                              alt="Glossy"
                            />
                          )}
                        </Link>
                      </div>

                      <div className="bg-white   w-full h-[15%]">
                        <h1
                          style={{ fontSize: "22px" }}
                          className="text-black text-center font-"
                        >
                          {item.name}
                        </h1>
                      </div>
                    </div>
                    {/* <div className="md:w-[32%] w-full md:h-[33rem] h-[20rem] bg-white flex flex-col  border-[#d2ab66] border-[1px] rounded">
                      <div className="w-full h-[80%] overflow-hidden">
                        <img
                          // src={item.bannerimage.replace(/\\\//g, '/')}
                          src={img1}
                          className="hover:scale-125 transition-all duration-500 cursor-pointer w-full h-full object-cover "
                          alt="name1"
                        />
                      </div>

                      <div className="w-full px-5  p-2 min-h-[20%]  flex flex-col items-start justify-center">
                        <div className="w-full px-5  p-2 min-h-[20%]  flex flex-col items-start justify-center">
                          <div className="text-black w-full gap-2 pt-1 flex flex-col items-start">
                            <div className="flex justify-between w-full items-center">
                              <h1 style={{ fontSize: "22px" }}>{item.name}</h1>
                              <Link to={`/downloads/size/${item.id}`}>
                                <div className="cursor-pointer group w-12 m-1 h-12 flex items-center justify-center transition-all hover:bg-black  ml-auto rounded-full  text-end bg-[#d2ab66]">
                                  <AiOutlineEye className="text-black group-hover:text-[#d2ab66] text-2xl" />
                                </div>
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </Fade>
                ))}
              </div>
            </div>
          ) : (
            <div className="noData">
              <p>No data available.</p>
            </div>
          )
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )}
      </>
    );
};

export default PageSubCategoryDownload;
