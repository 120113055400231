// import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Component/Navbar";
import Footer from "./Component/Footer/Footer.jsx";
import { Routes, Route } from "react-router-dom";
import PageCollections from "./Component/PageCollections";
import ContactUs from "./Component/ContactUs";
import PageCatalogueDownolad from "./Component/PageCatalogueDownolad.jsx";
import PageSizeDownload from "./Component/PageSizeDownload.jsx";
import PageSubCategoryDownload from "./Component/PageSubCategoryDownload.jsx"; 
import PageCatalogueCollection from "./Component/PageCatalogueCollection.jsx"; 
import PageCategoryDownload from "./Component/PageCategoryDownload.jsx";
import PageNetwork from "./Component/PageNetwork";
import Home from "./Component/Home";
import AboutUs from "./Component/About/AboutUs.jsx";
import Subcollections from "./Component/Subcollections.jsx";
import FlipComponent from "./Component/FlipComponent.jsx";
import Termas from "./Component/Cms/Termas.jsx";
import Privecy from "./Component/Cms/Privecy.jsx";
import { useLocation } from "react-router-dom";

function App() {
  
  const url = useLocation();
  return (
    <>
      {!url.pathname.includes("/readCatalogue/") && (
        <div className="mb-[4.5rem]">
          <Navbar />
        </div>
      )}

      <Routes>
        <Route path={"/"} element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        {/* <Route path="/downloads" element={<PageDownolads />} /> */}
        <Route path="/downloads/category" element={<PageCategoryDownload />} />
        <Route path="/downloads/subcategory/:id" element={<PageSubCategoryDownload />} />
        <Route path="/downloads/size/:id" element={<PageSizeDownload />} />
        <Route path="/downloads/catalogue/:sizeId/:categoryId" element={<PageCatalogueDownolad />} />
        <Route path="/network" element={<PageNetwork />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/collections" element={<PageCollections />} />
        <Route path="/collections/size/:id" element={<Subcollections />} />
        <Route path="/collections/catalogue/:sizeId/:collectionId" element={<PageCatalogueCollection />} />
        <Route path="/downloads/readCatalogue/:id" element={<FlipComponent />} />
        <Route path="/terms" element={<Termas />} />
        <Route path="/privacy" element={<Privecy />} />
      </Routes>

      {!url.pathname.includes("/readCatalogue/") && (
          <Footer />
      )}
    </>
  );
}

export default App;
