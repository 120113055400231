import React, { useState, useEffect, useRef } from "react";
import loader from '../images/loader2.gif'
const LazyImage = ({ src, alt, CclassName }) => {
  const [imageSrc, setImageSrc] = useState(null);
  const imageRef = useRef();

  useEffect(() => {
    let observer;
    const currentImage = imageRef.current;

    if (currentImage) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setImageSrc(src);
              observer.unobserve(currentImage);
            }
          });
        },
        { threshold: 0.5 } // Adjust the threshold as needed
      );

      observer.observe(currentImage);
    }

    return () => {
      if (observer) observer.disconnect();
    };
  }, [src]);

  return (
    <>
      {/* {imageSrc ? ( */}
      <img
        ref={imageRef}
        style={
          !imageSrc && CclassName === "HomeSlider" ? { width: "300px" } : {}
        }
        src={imageSrc ? imageSrc : loader} // Replace with your placeholder image
        alt={alt}
        className={CclassName}
      />
      {/* ) : (
        <div className="skeleton-img"></div>
      )} */}
    </>
  );
};

export default LazyImage;