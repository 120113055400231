import React from 'react'

const SocialIcons = ({ Icons }) => {
  console.log(Icons);
  return (
    <div className="lg:flex sm:flex md:flex justify-center">
      {Icons?.map((icon) => {

        return (icon.redirect&& <a
          href={icon.redirect}
          key={icon.name}
          target="_blank"
          rel="noreferrer"
          className="p-2 cursor-pointer inline-flex items-center
            rounded-full bg-gray-700 mx-1.5 text-xl hover:text-green-100 hover:bg-[#d2ab66] 
            duration-300 "
        >
          {/* <ion-icon name={icon.name}> </ion-icon> */}
          <div className="w-6 h-6">
            <img
              className="w-full h-full object-cover"
              src={icon.link}
              alt=""
            />
          </div>
        </a>)
      })}
    </div>
  );
}

export default SocialIcons