import React, { useEffect, useState } from "react";
// import primumimg from "../images/premiumFloring.png";
// import { motion } from "framer-motion";
import LazyImage from "./LazyImage.jsx";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Zoom from "react-reveal/Zoom";
import "aos/dist/aos.css";

const PrimumFloring = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async() => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${baseUrl}/api/premiumBanner`); // Replace with the actual API endpoint
        const jsonData = await response.data.data;
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        <div className="container-fluid flex items-center justify-center">
          <div className="container gap-10 flex md:flex-row flex-col items-center justify-center w-full p-[1rem]">
            <Fade duration={1200} left bottom>
              <div className="md:flex-[0.5] gap-2 md:p-[2rem] md:gap-6 md:w-[50%] w-full h-full flex md:items-start items-center justify-center flex-col">
                <h1 className="text-white md:text-3xl text-md font-bold">
                  {data.title}
                </h1>
                <p className="text-white text-justify md:text-md font-semibold">
                  {data.description}
                </p>
              </div>
            </Fade>
            <Zoom duration={1200} center>
              <div className="md:flex-[0.5] md:p-[2rem] md:w-[50%] w-full h-full flex items-center justify-center ">
                <div className="md:w-full w-full h-full">
                  {/* <img
              src={primumimg}
              alt="Preimum"
              className="w-full h-full object-cover"
            /> */}
                  <LazyImage
                    src={encodeURI(baseUrl + "/" + data.image)}
                    alt={`Preimum`}
                    CclassName="w-full h-full object-cover rounded"
                  />
                </div>
              </div>
            </Zoom>
          </div>
        </div>
      ) : (
        <div className="noData">
          <p>No data available.</p>
        </div>
      )}
    </>
  );
};

export default PrimumFloring;
