import React, { useEffect, useCallback, useRef } from "react";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import {
  setData,
  setIsLoading,
  setActiveSlide,
  setSliderReady,
} from "./actions";

const IMAGE_PARTS = 4;
const AUTOCHANGE_TIME = 8000;

const CitiesSlider = () => {
  const dispatch = useDispatch();
  const { data, isLoading, activeSlide, sliderReady, prevSlide } = useSelector(
    (state) => state
  );
  let firstFun = useRef(true);
  let timeoutId = useRef(null);
  const baseUrl = process.env.REACT_APP_API_URL;
  const changeSlides = useCallback(
    (change) => {
        clearTimeout(timeoutId.current);
      const { length } = data;
      const prevSlideValue = activeSlide;
      let newActiveSlide = prevSlideValue + change;
      if (newActiveSlide < 0) newActiveSlide = length - 1;
      if (newActiveSlide >= length) newActiveSlide = 0;
      dispatch(setActiveSlide(newActiveSlide));
    },
    [data, activeSlide, dispatch]
  );

  const runAutochangeTO = useCallback(() => {
    setTimeout(() => {
      changeSlides(1);
      setTimeout(() => {
        runAutochangeTO();
      }, 500);
    }, AUTOCHANGE_TIME);
  }, [changeSlides]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${baseUrl}/api/banner`);
        const jsonData = response.data.data;
        dispatch(setData(jsonData));
        console.log(jsonData);
        dispatch(setIsLoading(false));

        if (jsonData) {
          dispatch(setActiveSlide(0));
          dispatch(setSliderReady(true));
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        dispatch(setIsLoading(false));
      }
    };

    fetchData();
  }, [dispatch]);

  useEffect(() => {
    const runAutochangeTO = () => {
        clearTimeout(timeoutId.current);
      console.log("runAutochangeTO");
      timeoutId.current = setTimeout(() => {
        changeSlides(1);
        clearTimeout(timeoutId);
        runAutochangeTO();
      }, AUTOCHANGE_TIME);

      return () => {
        clearTimeout(timeoutId.current);
      };
    };

    if (sliderReady && firstFun) {
      firstFun.current = false;
      console.log("enter");
      runAutochangeTO();
    }
  }, [sliderReady, changeSlides,firstFun]);

  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        data.length > 0 ? (
          <div style={{marginTop:'83px'}} className={classNames("slider", { "s--ready": sliderReady })}>
            <div className="slider__slides">
              {
                // slides1.length > 0 ? (
                data.map((slide, index) => (
                  <>
                    {/* {showBannerImage.includes(slide.id) ? ( */}
                    <>
                      {/* <p className="slider__top-heading">Tiles</p> */}
                      <div
                        className={classNames("slider__slide", {
                          "s--active": activeSlide === index,
                          "s--prev": prevSlide === index,
                        })}
                        key={slide.title}
                      >
                        <div className="slider__slide-content" style={{color: slide.textcolor ? slide.textcolor : '#fff' }}>
                          {/* <h2 className="slider__slide-heading">
                            {slide.title.split("").map((l, i) => (
                              <span key={i}>{l}</span>
                            ))}
                          </h2> */}
                          <h3 className="slider__slide-subheading text-center">
                            {slide.description || slide.title}
                          </h3>
                        </div>
                        <div className="slider__slide-parts">
                          {[...Array(IMAGE_PARTS).fill()].map((x, i) => (
                            <div className="slider__slide-part" key={i}>
                              <div
                                className="slider__slide-part-inner"
                                style={{
                                  backgroundImage: `url(${
                                    encodeURI(baseUrl + "/" + slide.image)
                                  })`,
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                    {/* ) : (
                      <>
                        <img
                          src={encodeURI(baseUrl + "/" + slide.image)}
                          onLoad={() => setshowBannerImageFun(slide.id)}
                          style={{ display: "none" }}
                          alt={slide.id}
                        />
                        <div className="skeleton-img1"></div>
                      </>
                    )} */}
                  </>
                ))
              }
            </div>
            {/* <div className="slider__control" onClick={() => changeSlides(-1)} />
      <div
        className="slider__control slider__control--right"
        onClick={() => changeSlides(1)}
      /> */}
          </div>
        ) : (
          <div className="noData">{/* <p>No data available.</p> */}</div>
        )
      ) : (
        <div className="noData">{/* <p>No data available.</p> */}</div>
      )}
    </>
  );
};

export default CitiesSlider;
