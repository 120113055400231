import React, { useEffect,useState } from "react";
import { RESOURCES} from "./Menus";
import Item from "./Item";

import { Icons } from "./Menus";
import SocialIcons from "./SocialIcons";
import { Link } from "react-router-dom";
import logo from "../../images/logo.png";
import axios from "axios";

const ItemContainer = () => {
  const [data,setData] =useState([])
  
  useEffect(()=>{
    getLinks();
  },[])

  const getLinks = async ()=>{
    const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${baseUrl}/api/socialmedia`
      );
      const jsonData = await response.data;
        // setData(jsonData)
        Icons[0].redirect =  jsonData.facebook_link
        Icons[1].redirect =  jsonData.twitter_link
        Icons[2].redirect =  jsonData.linkedin_link
        Icons[3].redirect =  jsonData.wp_number ? `https://wa.me/${jsonData.wp_number}` :''
        Icons[4].redirect =  jsonData.insta_link
        setData(Icons)
  }

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-6 sm:px-8 px-5 py-16">
      <ul className="-mt-10 flex flex-col justify-between">
        <Link to={`/`}>
          <div className="" style={{ width: "120px" }}>
            <img
              src={logo}
              alt="logoimage"
              className="w-full h-full object-cover"
            />
          </div>
        </Link>
        <h1 className="mb-1 font-semibold text-xl" style={{ fontSize: "30px" }}>
          Address
        </h1>
        <li>
          <Link
            style={{ fontSize: "18px" }}
            className="text-white hover:text-[#d2ab66] duration-300
        text-sm cursor-pointer leading-6"
            to={"https://maps.app.goo.gl/hFwj3ivcayd7f2MfA"}
          >
            <b>Registered Office:</b> Shop No. 107, Pacific Business Park,
            Mahendra Nagar, Morbi - 363642.
          </Link>
        </li>

        <li>
          <Link
            style={{ fontSize: "18px" }}
            className="text-white hover:text-[#d2ab66]  duration-300
        text-sm cursor-pointer leading-6"
            to={"https://maps.app.goo.gl/KMSDDXkvEnEyC2KLA"}
          >
            <b>Sales Office:</b> C/o. Swaroop Dispo Plast, Nr. Gold Stone
            Ceramic, Dharampur Road, Morbi - 363642.
          </Link>
        </li>
        <li>
          <Link
            style={{ fontSize: "18px" }}
            className="text-white hover:text-[#d2ab66]  duration-300
        text-sm cursor-pointer leading-6"
            to={"https://maps.app.goo.gl/AcnxyvSauK3eZjCs6"}
          >
            <b>Branch Office:</b> B/324, Aarohi Galleria, Aarohi Crest Road,
            South Bopal, Ahmedabad - 380058.
          </Link>
        </li>
        <li>
          <b>Contact Numbers:</b>
          <br />
          <Link
            style={{ fontSize: "18px" }}
            className="text-white hover:text-[#d2ab66]  duration-300
            text-sm cursor-pointer leading-6"
            to={"tel:+917990150164"}
          >
            (+91) 7990150164 ,&nbsp;
          </Link>
          <Link
            style={{ fontSize: "18px" }}
            className="text-white hover:text-[#d2ab66]  duration-300
            text-sm cursor-pointer leading-6"
            to={"tel:+918530300700"}
          >
            (+91) 8530300700 ,&nbsp;
          </Link>
          <Link
            style={{ fontSize: "18px" }}
            className="text-white hover:text-[#d2ab66]  duration-300
            text-sm cursor-pointer leading-6"
            to={"tel:+917383455555"}
          >
            &nbsp;(+91) 7383455555
          </Link>
        </li>
      </ul>
      <div className="lg:mt-20 sm:mt-20 md:mt-20">
        <Item Links={RESOURCES} title="PAGES" />

        <div className="mt-7">
          <SocialIcons Icons={data}/>
        </div>
      </div>

      {/* <Item Links={SUPPORT} title="SUPPORT" /> */}
    </div>
  );
};

export default ItemContainer;
