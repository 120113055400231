import React,{useState,useEffect} from "react";
import background from "../images/background.png";
import { motion } from "framer-motion";
// import AreaImage from "../images/AreaImage.jpg";
import axios from "axios";

const ProffsionalArea = () => {
  const divStyle = {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    // You can adjust this property to control the image size and scaling
    // Other CSS properties can also be set here
  };
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const baseUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(
          `${baseUrl}/api/professionalAreaBanner`
        ); // Replace with the actual API endpoint
        const jsonData = await response.data.data;
        setData(jsonData);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        <div
          className="container-fluid flex items-center justify-center bg-fixed"
          style={divStyle}
        >
          <div className="container p-[2rem] gap-2 flex items-center justify-center md:flex-row flex-col">
            <div className="md:w-[50%] md:flex-[0.5] w-[100%] md:p-[2rem] p-0 flex items-center justify-center">
              <motion.div
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.5 }}
                className="md:w-[30rem] h-[18rem] w-full bg-[#d2ab66] p-2 md:rounded-full  md:h-[30rem]"
              >
                <motion.img
                  initial={{ y: -100, opacity: 0 }}
                  whileInView={{ y: 0, opacity: 1 }}
                  transition={{ duration: 1.5 }}
                  src={encodeURI(baseUrl + "/" + data.image)}
                  alt="image"
                  className="w-full h-full object-cover md:rounded-full"
                />
              </motion.div>
            </div>
            <div className="md:w-[50%] gap-4 md:flex-[0.5] w-[100%] md:p-[2rem] p-0 flex flex-col items-start justify-center">
              <motion.h1
                initial={{ x: -100, opacity: 0 }}
                whileInView={{ x: 0, opacity: 1 }}
                transition={{ duration: 1.2 }}
                className="md:text-3xl text-md text-black font-bold"
              >
                {data.title}
              </motion.h1>
              <motion.p
                initial={{ y: -100, opacity: 0 }}
                whileInView={{ y: 0, opacity: 1.1 }}
                transition={{ duration: 1.5 }}
                className="md:text-xl text-sm text-black text-justify"
              >
                {data.description}
              </motion.p>
            </div>
          </div>
        </div>
      ) : (
        <div className="noData">
          <p>No data available.</p>
        </div>
      )}
    </>
  );
};

export default ProffsionalArea;
