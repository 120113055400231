import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const SubAbout = () => {
  return (
    <div className="container-fulid flex   items-center justify-center p-3 px-5 md:px-[5rem]">
      <div className="container flex md:gap-[1.5rem] gap-3 items-start justify-center flex-col">
        <motion.h1
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.5 }}
          className="md:text-2xl text-md"
        >
          About Avio
        </motion.h1>
        <motion.p
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.6 }}
          className="text-justify md:text-xl text-md"
        >
          {" "}
          Avio Ceramic, a leading ﬁrm in the trading of all ranges of tiles, has
          a presence at over 250 retailers/wholesalers across India with a wide
          range of solutions for ﬂoors and coverings which ranges from contracts
          to the residential industry, responding to all building requirements
        </motion.p>
        <motion.p
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1.7 }}
          className="text-justify md:text-xl text-md"
        >
          We have created a team of experienced professionals with extensive
          long-term experience in their respective ﬁelds. The experience and
          knowledge of the team results from observing trends and analysing
          market results. Thanks to this, we can provide not only a modern, but
          also an eﬀective approach to business.
        </motion.p>
        <Link to="/about">
          <motion.button
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 1.5 }}
            className="text-white hover:text-[#d2ab66] transition-all ease-in-out md:text-xl text-mds cursor-pointer"
          >
            Read More..
          </motion.button>
        </Link>
        <button></button>
      </div>
    </div>
  );
};

export default SubAbout;
