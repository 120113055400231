    import React, {useState} from "react";
    // import { motion } from "framer-motion";
    import Fade from 'react-reveal/Fade'
    import LightSpeed from "react-reveal/LightSpeed";
    import Zoom from "react-reveal/Zoom";
    import { Link } from "react-router-dom";
import axios from "axios";
    

    const ContactUs = () => {
  const baseUrl = process.env.REACT_APP_API_URL;
  const [state, setState] = useState({
    name: "",
    email: "",
    mobile: "",
    subject: "",
  });
      const [buttonDisable,setButtonDisable] = useState(false);
  // const [showSuccessModal, setShowSuccessModal] = useState(false);
  // const [showErrorModal, setShowErrorModal] = useState(false);
        const handleInputChange = (event) => {
            const {name, value} = event.target;
            setState((prevProps) => ({
                ...prevProps,
                [name]: value,
            }));
        };

  // const handleCloseSuccessModal = () => setShowSuccessModal(false);
  // const handleCloseErrorModal = () => setShowErrorModal(false);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setButtonDisable(true);

    try {
      const response = await axios.post(`${baseUrl}/api/contact-us`, state);
      const jsonData = response.data;
      if (jsonData) {
        setState({
          name: "",
          email: "",
          mobile: "",
          subject: "",
        });
        alert('Submitted')
        // setShowSuccessModal(true);
      }
    } catch (error) {
        alert('Error')
      console.error("Error submitting form:", error);
      // setShowErrorModal(true);
    } finally {
      setButtonDisable(false);
    }
  };
        return (
          <div className="container-fluid flex items-center md:gap-[2rem] justify-center flex-col md:p-[2rem] p-[3rem]">
            <div className="container flex flex-col items-center justify-center">
              <div className="md:w-[100%] h-[100%] w-full md:flex-[0.5]">
                <h1 className="text-center md:text-3xl text-2xl font-bold pb-3">
                  Contact Us
                </h1>
                <div className="flex justify-center">
                  <form
                    className="flex md:gap-[1rem] gap-[1rem] md:w-[100%] lg:w-[80%]  w-full justify-center h-full flex-col md:p-[2rem] p-[1rem]"
                    onSubmit={handleSubmit}
                  >
                    <div className="lg:flex md:flex justify-between">
                      <div className="lg:flex lg:w-[45%] md:flex md:w-[47%] w-[100%] flex-col">
                        <Fade duration={800} left>
                          <label style={{ fontSize: "18px" }}>
                            Name*
                          </label>
                          <input
                            type="text"
                            name="name"
                            required
                            value={state.name}
                            className="text-white w-[100%] p-1 outline-none bg-transparent border-[#d2ab66] border-[1px]  rounded p-3"
                            onChange={handleInputChange}
                          />
                        </Fade>
                      </div>
                      <div className="lg:flex lg:w-[45%] md:flex md:w-[47%] flex-col">
                        <Fade duration={1000} left>
                          <label style={{ fontSize: "18px" }}>Email</label>
                          <input
                            type="text"
                            name="email"
                            value={state.email}
                            className="text-white p-1 w-[100%] outline-none bg-transparent border-[#d2ab66] border-[1px] rounded p-3"
                            onChange={handleInputChange}
                          />
                        </Fade>
                      </div>
                    </div>
                    <div className="lg:flex md:flex justify-between">
                      <div className="lg:flex lg:w-[45%] md:flex md:w-[47%] flex-col">
                        <Fade duration={1200} left>
                          <label style={{ fontSize: "18px" }}>
                            Phone*</label>
                          <input
                            type="tel"
                            name="mobile"
                            required
                            pattern="[0-9]{10}"
                            value={state.mobile}
                            className="text-white p-1 w-[100%] outline-none bg-transparent border-[#d2ab66] border-[1px] rounded p-3"
                            onChange={handleInputChange}
                          />
                        </Fade>
                      </div>
                      <div className="lg:flex lg:w-[45%] md:flex md:w-[47%] flex-col">
                        <Fade duration={1400} left>
                          <label style={{ fontSize: "18px" }}>Subject</label>
                          <textarea
                            type="text"
                            rows={1}
                            name="subject"
                            value={state.subject}
                            className="text-white p-1 w-[100%] outline-none bg-transparent border-[#d2ab66] border-[1px] rounded p-3"
                            onChange={handleInputChange}
                          />
                        </Fade>
                      </div>
                    </div>
                    <div className="flex justify-center lg:mt-5">
                      <LightSpeed left duration={1400} delay={1000}>
                        <button
                          disabled={buttonDisable}
                          className="bg-[#d2ab66] p-2 md:w-[15rem] outline-none cursor-pointer border-transparent border-[1px] text-black hover:bg-transparent hover:border-[#d2ab66] md:mt-0 mt-5 hover:border-[1px] hover:text-white rounded"
                          type="submit"
                        >
                          Submit
                        </button>
                      </LightSpeed>
                    </div>
                    {/* <Fade duration={1400} left>
                      <Link
                        className="text-white hover:text-[#d2ab66] duration-300
                    text-md cursor-pointer leading-6 mt-3"
                        to={"mailto:info@avioceramic.com"}
                      >
                        <b>Email:</b> info@avioceramic.com
                      </Link>
                    </Fade> */}
                  </form>
                </div>
              </div>

              <Fade right duration={1400}>
                {/* <div>
                  <b>Contacts:</b> <br />
                  <Link
                    className="text-white hover:text-[#d2ab66]  duration-300
                    text-md  cursor-pointer leading-6 mt-3"
                    to={"tel:+917990150164"}
                  >
                    (+91) 7990150164 , &nbsp;
                  </Link>
                  <Link
                    className="text-white hover:text-[#d2ab66]  duration-300
                    text-md  cursor-pointer leading-6 mt-3"
                    to={"tel:+918530300700"}
                  >
                    (+91) 8530300700 , &nbsp;
                  </Link>
                  <Link
                    className="text-white hover:text-[#d2ab66]  duration-300
                    text-md  cursor-pointer leading-6 mt-3"
                    to={"tel:+917383455555"}
                  >
                    (+91) 7383455555
                  </Link>
                </div> */}
              </Fade>
              <div className="md:w-[100%] h-[100%] w-full md:flex-[0.5] flex-1  md:p-[2rem] p-[1rem]">
                <div className="lg:flex md:flex justify-center">
                  <div className="contactAddress align-center w-[100%]">
                    <Zoom right duration={1200} delay={1300}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.1767889073503!2d70.83957127436152!3d22.832947923379844!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598dab7ea5c16f%3A0xa72f161bf3a8c228!2sAVIO%20CERAMIC!5e0!3m2!1sen!2sin!4v1699186054276!5m2!1sen!2sin"
                        width="90%"
                        title="mapoftiles"
                        height="450"
                        style={{ border: "0" }}
                        allowFullScreen
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Zoom>
                    <Link
                      className="text-white w-[90%] hover:text-[#d2ab66] duration-300
                    text-md cursor-pointer leading-6 mt-3"
                      to={"https://maps.app.goo.gl/KMSDDXkvEnEyC2KLA"}
                    >
                      <b>Sales Office:</b> <br />
                      C/o. Swaroop Dispo Plast, Nr. Gold Stone Ceramic,
                      Dharampur Road, Morbi - 363642.
                    </Link>
                  </div>
                  <div className="contactAddress align-center w-[100%]">
                    <Zoom right duration={1200} delay={1000}>
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14688.596486702212!2d72.4693274!3d23.0182963!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9ba0cd74fead%3A0x4bbaa290178a4708!2sAvio%20Ceramic!5e0!3m2!1sen!2sin!4v1701690198070!5m2!1sen!2sin"
                        width="90%"
                        title="mapoftiles"
                        height="450"
                        style={{ border: "0" }}
                        allowFullScreen
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                      ></iframe>
                    </Zoom>
                    <Link
                      className="text-white hover:text-[#d2ab66] w-[90%] duration-300
                    text-md  cursor-pointer leading-6 mt-3"
                      to={"https://maps.app.goo.gl/AcnxyvSauK3eZjCs6"}
                    >
                      <b>Branch Office:</b> <br />
                      B/324, Aarohi Galleria, Aarohi Crest Road, South Bopal,
                      Ahmedabad - 380058.
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
    };

    export default ContactUs;
