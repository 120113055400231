// reducers.js
const initialState = {
    data: [],
    isLoading: true,
    activeSlide: 0,
    sliderReady: false,
    prevSlide:0,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_DATA':
            return { ...state, data: action.payload };
        case 'SET_IS_LOADING':
            return { ...state, isLoading: action.payload };
        case 'SET_ACTIVE_SLIDE':
            return { ...state, activeSlide: action.payload };
        case 'SET_SLIDER_READY':
            return { ...state, sliderReady: action.payload };
        case 'SET_PREV_SLIDE_READY':
            return { ...state, sliderReady: action.payload };
        default:
            return state;
    }
};

export default reducer;
