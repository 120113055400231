import React from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import Zoom from 'react-reveal/Zoom';
// import { Autoplay, EffectCreative, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css/effect-creative';
import image1 from '../images/banner1.jpg';
import image2 from '../images/banner2.jpg';
import image3 from '../images/banner3.jpg';
import "../sliderManual.css";
// import "../sliderManual.js";
import CitiesSlider from "./homeSlider/CitiesSlider";

const HeroArea = () => {
const slides = [
  {
    image: image1,
    id: 1,
    content:
      "Transform your space with elegance underfoot – our exquisite tiles redefine luxury and style.",
    title: "Glossy",
  },
  {
    image: image2,
    id: 2,
    content:
      "Discover a world of possibilities at your feet – our versatile tiles blend creativity with functionality.",
    title: "Sugar",
  },
  {
    image: image3,
    id: 3,
    content:
      "Elevate your surroundings with precision-crafted tiles – where innovation meets timeless design.",
    title: "Carving",
  },
];
  return (
    <>
      <CitiesSlider slides={slides} />
    </>
  );
};

export default HeroArea;
