import { React, useEffect, useState, useCallback } from "react";
import { Link, useParams } from "react-router-dom";

import Zoom from "react-reveal/Zoom";
import imageNotFound from "../images/imageNotFound.png";
import LazyImage from "./LazyImage.jsx";
import axios from "axios";
const Subcollections = () => {
  const params = useParams();
  const fetchData = useCallback(async () => {
    try {
  const baseUrl = process.env.REACT_APP_API_URL;
      const response = await axios.get(
        `${baseUrl}/api/getSizebyCollectionid/${params.id}`
      ); // Replace with the actual API endpoint
      const jsonData = await response.data;
      setData(jsonData);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  }, [params.id]);

  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const baseUrl = process.env.REACT_APP_API_URL;
  useEffect(() => {
    fetchData();
  }, [fetchData]);
  return (
    <>
      {isLoading ? (
        <div className="loader">
          <div className="loaderSpin"></div>
        </div>
      ) : data ? (
        data.length > 0 ? (
          <div className=" container-fluid flex flex-col items-center justify-center pt-[5rem] p-[2rem] gap-[2rem]">
            <div>
              <h1 className="md:text-3xl font-bold text-xl text-white">
                Look Size
              </h1>
            </div>
            <div className="h-[100%] container flex lg:flex-row items-start gap-3 justify-center flex-wrap">
              {data.map((item, index) => (
                <Zoom duration={1200} center>
                  <div className="lg:w-[15%] md:w-[30%] sm:w-[40%] h-[12rem] w-[70%] flex flex-col justify-between items-center rounded">
                    <div className="w-full h-[100%] overflow-hidden">
                      <Link
                        to={`/collections/catalogue/${item.id}/${params.id}`}
                      >
                        {item.bannerimage ? (
                          <LazyImage
                            key={index}
                            CclassName="hover:scale-110 transition-all duration-500 cursor-pointer w-full h-full object-cover"
                            src={encodeURI(baseUrl + "/" + item.bannerimage)}
                            alt={`Image ${index + 1}`}
                          />
                        ) : (
                          <img
                            src={imageNotFound}
                            className="cursor-pointer w-full h-full object-cover"
                            alt="Glossy"
                          />
                        )}
                      </Link>
                    </div>

                    <div className="bg-white   w-full h-[20%]">
                      <h1
                        style={{ fontSize: "22px" }}
                        className="text-black text-center font-"
                      >
                        {item.name}
                      </h1>
                    </div>
                  </div>
                </Zoom>
              ))}
            </div>
          </div>
        ) : (
          <div className="noData">
            <p>No data available.</p>
          </div>
        )
      ) : (
        <div className="noData">
          <p>No data available.</p>
        </div>
      )}
    </>
  );
};

export default Subcollections;
